import React, { FunctionComponent, useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'gatsby';
import { MdMenu, MdSettings } from 'react-icons/md';
import MobileNavMenu from './mobile-nav-menu';
import ButtonLink from './button-link';
import Image from '@/components/image';
import { PageContext, NavbarData } from '@/utils/types';
import { getButtonAppearance } from '@/utils/button';
import LocaleSwitch from '@/components/locale-switch';
import ThemeSwitch from '@/components/theme-switch';
import { localizePath } from '@/utils/localize';
import StyledLink from './styled-link';
import SettingsMenu, { MenuItem } from './settings-menu';
import { useSelector } from 'react-redux';
import { State } from '@/store/store';
import { AuthState } from '@/store/reducers/auth-reducer';

export interface NavbarProps {
  navbar: NavbarData;
  pageContext: PageContext;
}

const Navbar: FunctionComponent<NavbarProps> = ({ navbar, pageContext }) => {
  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false);

  const [items, setItems] = useState<MenuItem[]>([]);

  const { isLoggedIn, isAdmin } = useSelector<State, AuthState>(
    (state) => state.auth,
  );

  useEffect(() => {
    if (isLoggedIn) {
      const items: MenuItem[] = [];
      if (isAdmin) items.push({ href: '/app/admin', name: 'Admin' });
      items.push({ href: '/app/account', name: 'Account' });
      items.push({ href: '/app/logout', name: 'Logout' });
      setItems(items);
    }
  }, [isLoggedIn, isAdmin]);

  return (
    <>
      {/* The actual navbar */}
      <nav className="border-lightgrey bg-primary dark:bg-deepblue py-6 sm:py-2 transition duration-500 relative z-20">
        <div className="container flex flex-row items-center justify-between">
          {/* Content aligned to the left */}
          <div className="flex flex-row items-center">
            <Link
              to={localizePath({ ...pageContext, isPreview: false, slug: '' })}
            >
              <Image
                style={{ width: '140px' }}
                media={navbar.darkLogo}
                className="h-8 w-auto object-contain"
              />
            </Link>
            {/* List of links on desktop */}
            <ul className="hidden list-none md:flex flex-row gap-4 items-baseline ml-10">
              {navbar.links.map((navLink) => (
                <li key={navLink.id}>
                  <StyledLink
                    link={{
                      ...navLink,
                      url: `${localizePath({
                        ...pageContext,
                        isPreview: false,
                        // Remove the '/'
                        slug: navLink.url.slice(1),
                      })}`,
                    }}
                    appearance={getButtonAppearance('primary', 'light')}
                    compact
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-center">
            {/* Locale Switch Mobile */}
            {pageContext.localizations && (
              <div className="md:hidden">
                <LocaleSwitch pageContext={pageContext} />
              </div>
            )}
            {/* Theme Switch Mobile */}
            <div className="md:hidden">
              <ThemeSwitch />
            </div>
            {/* Hamburger menu on mobile */}
            <button
              onClick={() => setMobileMenuIsShown(true)}
              className="p-1 block md:hidden"
            >
              <MdMenu className="h-8 w-auto dark:text-white ml-2 text-darkblue" />
            </button>
            {/* CTA button on desktop */}
            {navbar.button && (
              <div className="hidden md:block">
                <ButtonLink
                  button={navbar.button}
                  appearance={getButtonAppearance(navbar.button.type, 'light')}
                  compact
                />
              </div>
            )}

            {/* Locale Switch Desktop */}
            {pageContext.localizations && (
              <div className="hidden md:block">
                <LocaleSwitch pageContext={pageContext} />
              </div>
            )}
            {/* Theme Switch Desktop */}
            <div className="hidden md:block ml-2">
              <ThemeSwitch />
            </div>
            {/* Settings menu */}

            {isLoggedIn && <SettingsMenu items={items} />}
          </div>
        </div>
      </nav>

      {/* Mobile navigation menu panel */}
      {mobileMenuIsShown && (
        <MobileNavMenu
          navbar={navbar}
          closeSelf={() => setMobileMenuIsShown(false)}
        />
      )}
    </>
  );
};

export default Navbar;
