import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Appearance, ButtonLinkProp } from '@/utils/types';
import CustomLink from './custom-link';

interface ButtonContentProps {
  button: ButtonLinkProp;
  appearance: Appearance;
  compact: boolean;
}

interface ButtonLinkProps {
  button: ButtonLinkProp;
  appearance: Appearance;
  compact?: boolean;
}

const ButtonContent: FunctionComponent<ButtonContentProps> = ({
  button,
  appearance,
  compact = false,
}) => {
  return (
    <div
      className={classNames(
        // Common classes
        'block w-full lg:w-auto text-center uppercase tracking-wide font-semibold text-base md:text-sm border-2 rounded-md  transform hover:scale-105 transition duration-500 shadow-md',
        // Full-size button
        {
          'px-8 py-4': compact === false,
        },
        // Compact button
        {
          'px-6 py-2': compact === true,
        },
        // Specific to when the button is fully dark
        {
          'bg-primary text-white border-primary': appearance === 'dark',
        },
        // Specific to when the button is dark outlines
        {
          'text-primary border-primary': appearance === 'dark-outline',
        },
        // Specific to when the button is fully white
        {
          'bg-white text-primary border-white': appearance === 'white',
        },
        // Specific to when the button is white outlines
        {
          'text-white border-white': appearance === 'white-outline',
        },
      )}
    >
      {button.text}
    </div>
  );
};

const ButtonLink: FunctionComponent<ButtonLinkProps> = ({
  button,
  appearance,
  compact = false,
}) => {
  return (
    <CustomLink link={button}>
      <ButtonContent
        button={button}
        appearance={appearance}
        compact={compact}
      />
    </CustomLink>
  );
};

export default ButtonLink;
