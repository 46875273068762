import React, { CSSProperties, FunctionComponent } from 'react';
import { MediaProp, isDynamicImage } from '../utils/types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getStrapiMedia } from '../utils/media';
import classnames from 'classnames';

interface ImageProp {
  media?: MediaProp;
  className?: string;
  style?: CSSProperties;
  alt?: string;
}

const Image: FunctionComponent<ImageProp> = ({
  media,
  className,
  style,
  alt,
}) => {
  const altFinal =
    alt ?? media?.alternativeText ?? 'An image uploaded to Strapi';

  if (media && isDynamicImage(media)) {
    const image = getImage(media.localFile);
    if (image) {
      return (
        <GatsbyImage
          className={className}
          style={style}
          image={image}
          alt={altFinal}
        />
      );
    }
  } else if (media && !isDynamicImage(media)) {
    const strapiMedia = getStrapiMedia(media.url);
    if (strapiMedia) {
      return (
        <img
          src={strapiMedia}
          alt={altFinal}
          style={style}
          className={classnames(className, 'object-cover')}
        />
      );
    }
  }
  return <div>No image</div>;
};

export default Image;
