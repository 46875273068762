export type ButtonType = 'primary' | 'secondary';
export type Appearance = 'dark' | 'white-outline' | 'white' | 'dark-outline';

export interface Localization {
  locale: string;
  id: string;
}

export interface LocalizedPath {
  locale: string;
  href: string;
}

export interface PageContext {
  slug: string;
  id: string;
  locale: string;
  locales: string[];
  defaultLocale: string;
  isPreview?: boolean;
  localizations?: Localization[];
  localizedPaths?: LocalizedPath[];
  author: {
    name: string;
  };
  organization: {
    logo: string;
    name: string;
    url: string;
  };
  siteUrl: string;
}

export interface SiteMetadata {
  siteMetadata: {
    languages: {
      locales: string[];
      defaultLocale: string;
    };
    author: {
      name: string;
    };
    organization: {
      logo: string;
      name: string;
      url: string;
    };
    siteUrl: string;
  };
}

export interface Metadata {
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: MediaProp;
  twitterCardType?: string;
  twitterUsername?: string;
  published_at?: string;
}

export interface NavbarData {
  logo: MediaProp;
  darkLogo: MediaProp;
  links: LinkProp[];
  button: ButtonLinkProp;
}

export interface FooterData {
  logo: MediaProp;
  darkLogo: MediaProp;
  columns: [
    {
      id: string | number;
      title: string;
      links: LinkProp[];
    },
  ];
  smallText: string;
}

type NotificationBannerType = 'info' | 'warning' | 'alert' | 'nobanner';

export interface NotificationBannerData {
  text: string;
  type: NotificationBannerType;
}

export interface Global {
  metadata: Metadata;
  metaTitleSuffix: string;
  favicon: MediaProp;
  footer: FooterData;
  navbar: NavbarData;
  notificationBanner: NotificationBannerData;
}

export interface ButtonLinkProp {
  id: string;
  theme?: string;
  text: string;
  url: string;
  newTab?: boolean;
  type: ButtonType;
}

export interface LinkProp {
  id: string | number;
  url: string;
  text: string;
  newTab?: boolean;
}

export interface MediaPropLocal {
  localFile: any;
  alternativeText?: string;
}

export interface MediaPropRemote {
  id: string | number;
  alternativeText?: string;
  mime: string;
  url: string;
}

export interface User {
  email: string;
  password: string;
  username: string;
  role: string;
  token?: string;
}

export interface LoginReq {
  email: string;
  password: string;
}

export interface RegisterReq {
  email: string;
  password: string;
  username: string;
}

export interface ResetPasswordReq {
  email: string;
  password: string;
  token: string;
}

export type MediaProp = MediaPropLocal | MediaPropRemote;

export function isDynamicImage(image: MediaProp): image is MediaPropLocal {
  return (image as MediaPropLocal).localFile !== undefined;
}
