import React, { useContext, FunctionComponent } from 'react';
import Moon from './icons/moon';
import Sun from './icons/sun';
import { useSelector, useDispatch } from 'react-redux';
import { Actions, State } from '@/store/store';
import { Theme } from '@/models/theme';
import { toggleDarkModeAction } from '@/store/actions/theme-actions';

const ThemeSwitch: FunctionComponent = () => {
  const colorTheme = useSelector<State, Theme>(
    (state) => state.theme.colorTheme,
  );
  const dispatch = useDispatch();
  const toggleTheme = () => {
    dispatch<Actions>(toggleDarkModeAction());
  };
  return (
    <div
      onClick={() => {
        toggleTheme();
      }}
      className="h-10 w-10 dark:bg-primary bg-darkblue rounded-full shadow-lg cursor-pointer hover:text-textlightblue text-white flex
  justify-center items-center transform hover:scale-105 transition duration-500 "
    >
      {colorTheme === 'dark' ? <Moon /> : <Sun />}
    </div>
  );
};

export default ThemeSwitch;
