import React, {
  useEffect,
  useState,
  useRef,
  FunctionComponent,
  MutableRefObject,
} from 'react';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import { getLocalizedPage } from '../utils/localize-fetch';
import { localizePath } from '../utils/localize';
import WorldIcon from './icons/world';
import { MdExpandMore } from 'react-icons/md';
import { useCookies } from 'react-cookie';

import { useOnClickOutside } from '../utils/hooks';
import { PageContext } from '../utils/types';

interface LocaleSwitchProps {
  pageContext: PageContext;
}

const LocaleSwitch: FunctionComponent<LocaleSwitchProps> = ({
  pageContext,
}) => {
  const isMounted = useRef(false);
  const select = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const [cookies, setCookie] = useCookies();
  const [locale, setLocale] = useState(
    cookies.GATSBY_LOCALE || pageContext.locale,
  );
  const [showing, setShowing] = useState(false);

  const handleLocaleChange = async (selectedLocale: string) => {
    setCookie('GATSBY_LOCALE', selectedLocale, {
      path: '/',
      secure: Boolean(process.env.NODE_ENV),
      sameSite: 'strict',
    });
    setLocale(selectedLocale);
  };
  const handleLocaleChangeRef = useRef(handleLocaleChange);

  useOnClickOutside(select, () => setShowing(false));

  useEffect(() => {
    // Set the requested locale when no cookie locale is found
    if (!cookies.GATSBY_LOCALE) {
      handleLocaleChangeRef.current(pageContext.defaultLocale);
    }

    const changeLocale = () => {
      setShowing(false);
      if (
        !isMounted.current &&
        cookies.GATSBY_LOCALE &&
        cookies.GATSBY_LOCALE !== pageContext.locale
      ) {
        // Redirect to locale page if locale mismatch
        getLocalizedPage(cookies.GATSBY_LOCALE, pageContext).then(
          (localePage) => {
            navigate(localizePath({ ...pageContext, ...localePage }));
          },
        );
      }
    };

    changeLocale();

    return () => {
      isMounted.current = true;
    };
  }, [locale, pageContext, cookies.GATSBY_LOCALE]);

  return (
    <div ref={select} className="relative ml-4">
      <button
        className="text-white hover:text-textlightblue   focus:outline-none flex items-center justify-between px-2 py-2 cursor-pointer rounded-md w-20"
        onClick={() => setShowing(!showing)}
      >
        <WorldIcon />
        <span className="capitalize">{locale}</span>
        {pageContext.localizedPaths &&
          pageContext.localizedPaths.length > 0 && (
            <MdExpandMore className="ml-1 text-primary-600" />
          )}
      </button>
      {pageContext.localizedPaths && pageContext.localizedPaths.length > 0 && (
        <div
          className={`w-full bg-white dark:bg-darkblue p-1 mt-1 shadow-lg rounded-md ${
            showing ? 'absolute' : 'hidden'
          }`}
        >
          {pageContext.localizedPaths?.map(({ href, locale }) => {
            return (
              <Link
                to={href}
                key={locale}
                onClick={() => handleLocaleChange(locale)}
                role="option"
              >
                <p className="capitalize hover:bg-primary hover:text-white dark:text-white h-full cursor-pointer p-2 rounded-md text-center">
                  {locale}
                </p>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LocaleSwitch;
