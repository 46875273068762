import { fetchAPI } from './api';
import { PageContext } from './types';

export async function getLocalizedPage(
  targetLocale: string,
  pageContext: PageContext,
) {
  const localization = pageContext.localizations?.find(
    (localization) => localization.locale === targetLocale,
  );
  const localizationId = localization?.id ?? pageContext.defaultLocale;
  const localePage = await fetchAPI<PageContext>(`/pages/${localizationId}`);
  return localePage;
}
