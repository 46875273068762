import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import Image from '../image';
import { FooterData } from '@/utils/types';
import { curved } from '../../styles/footer.module.css';
import classNames from 'classnames';
import StyledLink from './styled-link';
import { BackgroundType, getButtonAppearance } from '@/utils/button';
import { useSelector } from 'react-redux';
import { State } from '@/store/store';
import { Theme } from '@/models/theme';

export interface FooterProps {
  footer: FooterData;
}

const Footer: FunctionComponent<FooterProps> = ({ footer }) => {
  const colorTheme = useSelector<State, Theme>(
    (state) => state.theme.colorTheme,
  );
  const linksAppearanceFromTheme = (colorTheme: Theme): BackgroundType => {
    return colorTheme === 'dark' ? 'light' : 'dark';
  };
  const [linksAppearance, setLinksAppearance] =
    useState<BackgroundType>('light');
  useEffect(() => {
    setLinksAppearance(linksAppearanceFromTheme(colorTheme));
  }, [colorTheme]);

  return (
    <footer
      className={classNames(
        // Common classes
        'mt-12 pt-12 bg-gray-100 dark:bg-deepblue',
        curved,
      )}
    >
      <div className="container flex flex-col lg:flex-row lg:justify-between">
        <div>
          {footer.logo && (
            <Image
              style={{ width: '140px' }}
              media={colorTheme === 'light' ? footer.logo : footer.darkLogo}
              className="h-8 w-auto object-contain"
            />
          )}
        </div>
        <nav className="flex flex-wrap flex-row lg:gap-20 items-start lg:justify-end mb-10">
          {footer.columns.map((footerColumn) => (
            <div
              key={footerColumn.id}
              className="mt-10 lg:mt-0 w-6/12 lg:w-auto"
            >
              <p className="dark:text-textlightblue text-darkblue uppercase tracking-wide font-semibold">
                {footerColumn.title}
              </p>
              <ul className="mt-2">
                {footerColumn.links.map((link) => (
                  <li key={link.id} className="py-1 px-1 -mx-1 -ml-3 ">
                    <StyledLink
                      link={link}
                      appearance={getButtonAppearance(
                        'primary',
                        linksAppearance,
                      )}
                      compact
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </nav>
      </div>
      <div className="text-sm bg-gray-200 py-6 text-darkblue dark:bg-darkblue dark:text-white">
        <div className="container">{footer.smallText}</div>
      </div>
    </footer>
  );
};

export default Footer;
