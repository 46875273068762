import React, {
  useEffect,
  useState,
  useRef,
  FunctionComponent,
  MutableRefObject,
} from 'react';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import { MdSettings } from 'react-icons/md';
import { useOnClickOutside } from '@/utils/hooks';

export interface MenuItem {
  href: string;
  name: string;
}

interface SettingsMenuProps {
  items: MenuItem[];
}

const SettingsMenu: FunctionComponent<SettingsMenuProps> = ({ items }) => {
  const select = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const [showing, setShowing] = useState(false);

  useOnClickOutside(select, () => setShowing(false));

  return (
    <div ref={select} className="relative ml-4">
      <button
        className="text-white hover:text-textlightblue   focus:outline-none flex items-center justify-between px-2 py-2 cursor-pointer rounded-md w-20"
        onClick={() => setShowing(!showing)}
      >
        <MdSettings className="h-6 w-auto text-white ml-2 hover:text-textlightgrey" />
      </button>
      {items && items.length > 0 && (
        <div
          className={`w-full bg-white dark:bg-darkblue p-1 mt-1 shadow-lg rounded-md ${
            showing ? 'absolute' : 'hidden'
          }`}
        >
          {items.map(({ href, name }) => {
            return (
              <Link to={href} key={name} role="option">
                <p className="capitalize hover:bg-primary hover:text-white dark:text-white h-full cursor-pointer p-2 rounded-md text-center">
                  {name}
                </p>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SettingsMenu;
