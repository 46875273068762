import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import { active } from '@/styles/styled-link.module.scss';
interface CustomLinkProp {
  link: {
    url: string;
    newTab?: boolean;
  };
}

const CustomLink: FunctionComponent<CustomLinkProp> = ({ link, children }) => {
  const isInternalLink = link.url.startsWith('/');

  // For internal links, use the Gatsby Link component
  if (isInternalLink) {
    return (
      <Link to={link.url} activeClassName={active}>
        {children}
      </Link>
    );
  }

  // Plain <a> tags for external links
  return (
    <a
      href={link.url}
      // Change target and rel attributes is newTab is turned on
      target={link.newTab ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default CustomLink;
