import React, { FunctionComponent } from 'react';
import { MdClose, MdChevronRight } from 'react-icons/md';
import Image from '../image';
import { MediaProp, LinkProp, ButtonLinkProp } from '../../utils/types';
import ButtonLink from './button-link';
import { useLockBodyScroll } from '../../utils/hooks';
import { getButtonAppearance } from '../../utils/button';
import CustomLink from './custom-link';

interface MobileNavMenuProp {
  navbar: {
    logo: MediaProp;
    darkLogo: MediaProp;
    links: LinkProp[];
    button: ButtonLinkProp;
  };
  closeSelf: any;
}

const MobileNavMenu: FunctionComponent<MobileNavMenuProp> = ({
  navbar,
  closeSelf,
}) => {
  // Prevent window scroll while mobile nav menu is open
  useLockBodyScroll();

  return (
    <div className="w-screen h-screen fixed top-0 left-0 overflow-y-scroll py-4 sm:py-2 bg-primary dark:bg-deepblue text-white z-30 pb-6">
      <div className="container h-full flex flex-col justify-start">
        {/* Top section */}
        <div className="flex flex-row justify-between py-2 items-center">
          {/* Company logo */}
          <Image
            style={{ width: '140px' }}
            media={navbar.darkLogo}
            className="h-8 w-auto object-contain"
          />
          {/* Close button */}
          <button onClick={closeSelf} className="py-1 px-1">
            <MdClose className="h-8 w-auto" />
          </button>
        </div>
        {/* Bottom section */}
        <div className="flex flex-col justify-start w-9/12 mx-auto">
          <ul className="flex flex-col list-none gap-2 items-baseline text-xl mb-10">
            {navbar.links.map((navLink) => (
              <li key={navLink.id} className="block w-full">
                <CustomLink link={navLink}>
                  <div className="hover:text-textlightblue py-6 flex flex-row justify-between items-center">
                    <span>{navLink.text}</span>
                    <MdChevronRight className="h-8 w-auto" />
                  </div>
                </CustomLink>
              </li>
            ))}
          </ul>
          {navbar.button && (
            <ButtonLink
              button={navbar.button}
              appearance={getButtonAppearance(navbar.button.type, 'light')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileNavMenu;
