import React, { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import { NotificationBannerData } from '@/utils/types';

interface NotificationBannerProps {
  data: NotificationBannerData;
  closeSelf: () => void;
}

const NotificationBanner: FunctionComponent<NotificationBannerProps> = ({
  data: { text, type },
  closeSelf,
}) => {
  return (
    <div
      className={classNames(
        // Common classes
        'text-white',
        {
          // Apply theme based on notification type
          'bg-primary': type === 'info',
          'bg-third': type === 'warning',
          'bg-red': type === 'alert',
        },
      )}
    >
      {type !== 'nobanner' && (
        <div className="container flex flex-row justify-between items-center  relative z-20 mx-2 my-2">
          <div className="rich-text-banner flex-1">
            <ReactMarkdown>{text}</ReactMarkdown>
          </div>
          <button onClick={closeSelf} className="px-1 py-1 flex-shrink-0">
            <MdClose className="h-6 w-auto" color="#fff" />
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationBanner;
