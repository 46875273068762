// extracted by mini-css-extract-plugin
export var active = "styled-link-module--active--2fQiW";
export var styledLink = "styled-link-module--styled-link--3RKcM";
export var styledPrimary = "styled-link-module--styled-primary--1zgzd";
export var hover = "styled-link-module--hover--2Xmq1";
export var hoverOutwards = "styled-link-module--hover-outwards--3kdqE";
export var hoverInwards = "styled-link-module--hover-inwards--oxOS7";
export var styledWhite = "styled-link-module--styled-white--3RhcO";
export var hoverPrimary = "styled-link-module--hover-primary--35-8Z";
export var hoverWhite = "styled-link-module--hover-white--205qE";
export var hoverLeftToRight = "styled-link-module--hover-left-to-right--JraCr";
export var hoverRightToLeft = "styled-link-module--hover-right-to-left--2_Grm";