import { fetchAPI } from './server';
import { LocalizedPath, PageContext } from './types';

export function localizePath(page: PageContext) {
  const { locale, defaultLocale, slug, isPreview } = page;

  const splitSlug = slug.split('/');

  if (isPreview && slug) {
    // The preview requires a prefix
    return `/${locale}/preview/${slug}`;
  }

  if (locale === defaultLocale) {
    // The default locale is not prefixed
    return `/${slug}`;
  }

  if (splitSlug.length > 0 && splitSlug[0] === locale) {
    return `/${slug}`;
  }

  // The slug should have a localePrefix
  return `/${locale}/${slug}`;
}

export function getLocalizedPaths(page: PageContext): LocalizedPath[] {
  const paths = page.locales.map((locale) => {
    return {
      locale: locale,
      href: localizePath({ ...page, locale }),
    };
  });

  return paths;
}

export async function getLocalizedPathsArticle(
  page: PageContext,
): Promise<LocalizedPath[]> {
  if (!page.localizations || page.localizations.length == 0) {
    return [];
  }
  const paths = await Promise.all(
    page.localizations.map(async ({ id, locale }) => {
      const articles = await fetchAPI(`/articles?id=${id}&_locale=${locale}`);
      let slug = null;
      if (articles && articles.length > 0) {
        const article = articles[0];
        slug = article.slug;
      }
      return {
        locale: locale,
        href: `/article/${slug}`,
      };
    }),
  );

  return paths;
}
