import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import CustomLink from './custom-link';
import { Appearance, LinkProp } from '@/utils/types';

import {
  styledPrimary,
  styledWhite,
  styledLink,
  hover,
  hoverLeftToRight,
  hoverPrimary,
  hoverWhite,
} from '@/styles/styled-link.module.scss';

interface LinkContentProp {
  link: LinkProp;
  appearance: Appearance;
  compact: boolean;
}

interface StyledLinkProps {
  link: LinkProp;
  appearance: Appearance;
  compact?: boolean;
}

const LinkContent: FunctionComponent<LinkContentProp> = ({
  link,
  appearance,
  compact = false,
}) => {
  return (
    <div
      className={classNames(
        styledLink,
        // Common classes
        'block w-full lg:w-auto text-left',
        // Full-size button
        {
          'px-4 py-4': compact === false,
        },
        // Compact button
        {
          'px-2 py-2': compact === true,
        },
        // Specific to when the button is fully dark
        appearance === 'white' || appearance === 'dark-outline'
          ? styledPrimary
          : styledWhite,
      )}
    >
      <span
        className={classNames(
          hover,
          appearance === 'white' || appearance === 'dark-outline'
            ? hoverPrimary
            : hoverWhite,
          hoverLeftToRight,
        )}
      >
        {link.text}
      </span>
    </div>
  );
};

const StyledLink: FunctionComponent<StyledLinkProps> = ({
  link,
  appearance,
  compact = false,
}) => {
  return (
    <CustomLink link={link}>
      <LinkContent link={link} appearance={appearance} compact={compact} />
    </CustomLink>
  );
};

export default StyledLink;
