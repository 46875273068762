import {
  Global,
  isDynamicImage,
  LocalizedPath,
  Metadata,
  PageContext,
} from '@/utils/types';
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import SchemaOrg from './schema-org';

interface SEOProps {
  seo: Metadata & { article?: boolean };
  global: Global;
  pageContext: PageContext;
}

const SEO: FunctionComponent<SEOProps> = ({ seo, global, pageContext }) => {
  // Merge default and page-specific SEO values
  const fullSeo = {
    favicon: global.favicon,
    metaTitleSuffix: global.metaTitleSuffix,
    ...global.metadata,
    ...seo,
    author: pageContext.author,
    organization: pageContext.organization,
  };

  let imageUrl: string | undefined;
  if (fullSeo.shareImage) {
    const imageUrlPrefix = process.env.GATSBY_STRAPI_URL
      ? ''
      : 'http://localhost:8000';
    const imageUrlSuffix = isDynamicImage(fullSeo.shareImage)
      ? fullSeo.shareImage.localFile.url
      : fullSeo.shareImage.url;

    imageUrl = imageUrlPrefix + imageUrlSuffix;
  }

  const getMetaTags = () => {
    const tags = [];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: 'og:title',
          content: fullSeo.metaTitle,
        },
        {
          name: 'twitter:title',
          content: fullSeo.metaTitle,
        },
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: 'description',
          content: fullSeo.metaDescription,
        },
        {
          property: 'og:description',
          content: fullSeo.metaDescription,
        },
        {
          name: 'twitter:description',
          content: fullSeo.metaDescription,
        },
      );
    }
    if (imageUrl) {
      tags.push(
        {
          name: 'image',
          content: imageUrl,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        },
      );
    }
    if (fullSeo.article) {
      tags.push({
        property: 'og:type',
        content: 'article',
      });
    }
    tags.push({ name: 'twitter:card', content: 'summary_large_image' });

    return tags;
  };

  const metaTags = getMetaTags();

  const localizedPaths: LocalizedPath[] = pageContext.localizedPaths ?? [];

  const isBlogPost = fullSeo.article ?? false;
  const localePrefix =
    pageContext.locale === pageContext.defaultLocale
      ? ''
      : `${pageContext.locale}/`;
  const url = isBlogPost
    ? `${pageContext.siteUrl}/article/${pageContext.slug}`
    : `${pageContext.siteUrl}/${localePrefix}${pageContext.slug}`;

  return (
    <React.Fragment>
      <Helmet titleTemplate={`%s | ${fullSeo.metaTitleSuffix}`}>
        <title>{fullSeo.metaTitle}</title>
        {fullSeo.favicon && isDynamicImage(fullSeo.favicon) && (
          <link rel="icon" href={fullSeo.favicon.localFile.publicURL}></link>
        )}
        {localizedPaths.map(({ locale, href }) => {
          return (
            <link
              rel="alternate"
              hrefLang={locale}
              href={href}
              key={`alternate__${locale}`}
            ></link>
          );
        })}
        {metaTags.map((metaTag) => {
          return metaTag.name ? (
            <meta
              key={`meta_tag__${metaTag.name}`}
              name={metaTag.name}
              content={metaTag.content}
            ></meta>
          ) : (
            <meta
              key={`meta_tag__${metaTag.property}`}
              property={metaTag.property}
              content={metaTag.content}
            ></meta>
          );
        })}
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={url}
        title={fullSeo.metaTitle ?? ''}
        image={imageUrl ?? ''}
        description={fullSeo.metaDescription ?? ''}
        datePublished={fullSeo.published_at ?? ''}
        canonicalUrl={pageContext.siteUrl}
        author={fullSeo.author}
        organization={fullSeo.organization}
        defaultTitle={fullSeo.metaTitle ?? ''}
      />
    </React.Fragment>
  );
};

export default SEO;
