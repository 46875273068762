import React, { FunctionComponent, useEffect, useState } from 'react';
import Navbar from './elements/navbar';
import Footer from './elements/footer';
import NotificationBanner from './elements/notification-banner';
import { Global, PageContext } from '../utils/types';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Actions, State } from '@/store/store';
import { Theme } from '@/models/theme';
import { setThemeAction } from '@/store/actions/theme-actions';

interface LayoutProps {
  pageContext: PageContext;
  global: Global;
}

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  global,
  pageContext,
}) => {
  const { navbar, footer, notificationBanner } = global;
  const [bannerIsShown, setBannerIsShown] = useState(true);
  const [cookie, setCookie] = useCookies();
  const colorTheme = useSelector<State, Theme>(
    (state) => state.theme.colorTheme,
  );

  const switchTheme = (newColorTheme: Theme) => {
    const oldColorTheme: Theme = newColorTheme === 'dark' ? 'light' : 'dark';
    const root = window.document.documentElement;
    root.classList.remove(oldColorTheme);
    root.classList.add(newColorTheme);
    setCookie('GATSBY_THEME', newColorTheme, {
      path: '/',
      secure: Boolean(process.env.NODE_ENV),
      sameSite: 'strict',
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    switchTheme(colorTheme);
  }, [colorTheme]);

  useEffect(() => {
    if (cookie.GATSBY_THEME) {
      dispatch<Actions>(setThemeAction(cookie.GATSBY_THEME));
    }
  }, []);

  return (
    <div className="flex flex-col justify-between min-h-screen bg-white dark:bg-darkblue">
      {/* Aligned to the top */}
      <div className="flex-1">
        {notificationBanner && bannerIsShown && (
          <NotificationBanner
            data={notificationBanner}
            closeSelf={() => setBannerIsShown(false)}
          />
        )}
        <Navbar navbar={navbar} pageContext={pageContext} />
        <div className="dark:bg-darkblue transition duration-500">
          {children}
        </div>
      </div>
      {/* Aligned to the bottom */}
      <Footer footer={footer} />
    </div>
  );
};

export default Layout;
